import React, { FC } from 'react'
import { AddIcon, AvatarIcon, CheckedCircle, DocumentIcon, RemoveIcon } from '../../../icons'
import useTheme from '../../../hooks/useAppColor'


type DocumentItemProps = {
    neededBorder?: boolean
    title: string
    to?: string
}

export const DocumentItem: FC<DocumentItemProps> = ({ neededBorder = true, title = "", to = "" }) => {
    const getTheme = useTheme()
    return (
        <a href={to ? to : "#"} target={to ? "_blank" : undefined} className="d-f al-center gap-15 analysis-item pd-10-0 border-bottom w-100p">
            <DocumentIcon />
            <p style={{ maxWidth: 200 }} className={`text fw-5 ${getTheme("c-dark", "c-lg")}`}>{title}</p>
        </a>
    )
}
